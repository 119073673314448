import React, { useEffect } from 'react';
import { Root } from './style';

import { useState } from '@hookstate/core';

import { useSiteData } from 'utils';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import JoinTheCommunities from 'components/Common/JoinTheCommunities';
import RawHTML from 'components/Common/RawHTML';
import SiteData from 'components/Common/SiteData';
import SiteImage from 'components/Common/SiteImage';
import Page from './Page';

import { formatHODL, formatNumber } from 'utils';
import { useWeb3, getBalance, getBalanceBNB } from 'web3';

import vars from 'variables';

const HODLForward = ({
  pageContext: { hodlForwardCases, pageIndex, perPage },
}) => {
  const { bsc } = useWeb3();
  const { hodlForward: page } = useSiteData().pages;

  const state = useState({
    copied: false,
    raised: 0,
    worth: 0,
  });

  const onCopy = () => {
    state.copied.set(true);
    setTimeout(() => {
      state.copied.set(false);
    }, 1250);
  };

  useEffect(() => {
    const getBalances = async () => {
      const hodl = await getBalance(page.walletAddress);
      const bnb = await getBalanceBNB(page.walletAddress);
      state.bnb.set(bnb);
      state.hodl.set(hodl);
    };
    // fetch(`${vars.apiHost}/api/donated?to=${page.walletAddress}`)
    //   .then((response) => response.json())
    //   .then((json) => {
    //     const bnb = json[page.walletAddress.toLowerCase()].BNB;
    //     const hodl = json[page.walletAddress.toLowerCase()].HODL;
    //     state.bnb.set(bnb);
    //     state.hodl.set(hodl);
    //   })
    //   .catch(console.error);
    getBalances();
  }, []);

  return (
    <Root className="body-width">
      <h1>
        HODL <span>Forward</span>
      </h1>
      <RawHTML className="intro" html={page.intro} />
      <SiteImage className="main" image={page.image} />
      <Page
        collection={hodlForwardCases}
        page={page}
        perPage={perPage}
        pageIndex={pageIndex}
      />
      <div className="info">
        <div className="wrapper">
          <SiteData path="hodlForward.codeInfo" />
          <label>HODL Forward wallet address</label>
          <CopyToClipboard text={page.walletAddress} onCopy={onCopy}>
            <div className={`address ${state.copied.get() ? 'copied' : ''}`}>
              {page.walletAddress}
            </div>
          </CopyToClipboard>
          <br />
          <a
            className="button blue telegram"
            href={vars.links.telegramForward}
            target="_blank"
            rel="noreferrer"
          >
            Join the Telegram
          </a>
        </div>
        <SiteImage path="hodlForward.imageInfo" />
        <div className="achievements">
          <h4>
            {formatHODL(state.hodl.get())} HODL &amp;{' '}
            {formatNumber(state.bnb.get())} BNB raised
          </h4>
          <strong>
            Worth $
            {formatNumber(
              state.hodl.get() * bsc.hodlUsdPrice +
                state.bnb.get() * bsc.bnbUsdPrice
            )}
          </strong>
        </div>
      </div>
      <JoinTheCommunities />
    </Root>
  );
};

export default HODLForward;
